import React from 'react';
import { useTranslation } from 'react-i18next';

const DateFormatter = ({ date, format = 'medium' }) => {
  const { i18n } = useTranslation();
  
  if (!date) return null;
  
  // Convert string date to Date object if needed
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  // Format options based on the requested format
  let options;
  switch (format) {
    case 'short':
      options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      break;
    case 'long':
      options = { 
        weekday: 'long', 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric' 
      };
      break;
    case 'time':
      options = { hour: '2-digit', minute: '2-digit' };
      break;
    case 'datetime':
      options = { 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric',
        hour: '2-digit', 
        minute: '2-digit'
      };
      break;
    case 'medium':
    default:
      options = { day: 'numeric', month: 'long', year: 'numeric' };
      break;
  }
  
  // Format the date according to the current language
  return (
    <span className="formatted-date">
      {dateObj.toLocaleDateString(i18n.language === 'fr' ? 'fr-FR' : 'en-US', options)}
    </span>
  );
};

export default DateFormatter;
