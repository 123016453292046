import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './i18n/i18n';
import './assets/styles.css';

// Import components
import Home from './pages/Home';
import FamilyTree from './pages/FamilyTree';
import Events from './pages/Events';
import Contributions from './pages/Contributions';
import Transcripts from './pages/Transcripts';
import Login from './pages/Login';
// import Profile from './pages/Profile'; // Commented out as file is missing
// import Admin from './pages/Admin'; // Commented out as file is missing
// import NotFound from './pages/NotFound'; // Commented out as file is missing

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState('fr');

  useEffect(() => {
    // Check if user is already logged in
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');
    
    if (token && userData) {
      setIsAuthenticated(true);
      setUser(JSON.parse(userData));
    }
    
    // Set language preference
    const savedLanguage = localStorage.getItem('language') || 'fr';
    setCurrentLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleLogin = (userData, token) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setIsAuthenticated(true);
    setUser(userData);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <Router>
      <div className="app">
        <header className="header">
          <div className="container">
            <nav className="navbar">
              <div className="logo">Sanhoua<span>.com</span></div>
              <ul className="nav-links">
                <li><Link to="/">{t('home')}</Link></li>
                <li><Link to="/family-tree">{t('familyTree')}</Link></li>
                <li><Link to="/events">{t('events')}</Link></li>
                <li><Link to="/contributions">{t('contributions')}</Link></li>
                <li><Link to="/transcripts">{t('transcripts')}</Link></li>
                {/* {isAuthenticated && user?.role === 'admin' && (
                  <li><Link to="/admin">{t('admin')}</Link></li>
                )} */} {/* Commented out as Admin page is missing */}
              </ul>
              <div className="auth-language">
                {isAuthenticated ? (
                  <div className="user-menu">
                    {/* <Link to="/profile">{user?.username}</Link> */} {/* Commented out as Profile page is missing */}
                    <span>{user?.username}</span> {/* Display username directly */}
                    <button onClick={handleLogout}>{t('logout')}</button>
                  </div>
                ) : (
                  <Link to="/login" className="whatsapp-login">
                    <i className="fab fa-whatsapp"></i> {t('loginWithWhatsApp')}
                  </Link>
                )}
                <div className="language-switcher">
                  <button 
                    className={currentLanguage === 'fr' ? 'active' : ''} 
                    onClick={() => changeLanguage('fr')}
                  >
                    FR
                  </button>
                  <span>|</span>
                  <button 
                    className={currentLanguage === 'en' ? 'active' : ''} 
                    onClick={() => changeLanguage('en')}
                  >
                    EN
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </header>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/family-tree" element={<FamilyTree />} />
            <Route path="/events" element={<Events />} />
            <Route path="/contributions" element={<Contributions />} />
            <Route path="/transcripts" element={<Transcripts />} />
            <Route 
              path="/login" 
              element={<Login onLogin={handleLogin} />} 
            />
            {/* <Route 
              path="/profile" 
              element={
                isAuthenticated ? <Profile user={user} /> : <Login onLogin={handleLogin} />
              } 
            /> */} {/* Commented out as Profile page is missing */}
            {/* <Route 
              path="/admin" 
              element={
                isAuthenticated && user?.role === 'admin' ? <Admin /> : <div>Not Found</div> // Placeholder for NotFound
              } 
            /> */} {/* Commented out as Admin page is missing */}
            <Route path="*" element={<div>Page Not Found</div>} /> {/* Placeholder for NotFound */}
          </Routes>
        </main>

        <footer className="footer">
          <div className="container">
            <div className="footer-content">
              <div className="footer-section">
                <h3>{t('about')}</h3>
                <p>{t('footerAboutText')}</p>
              </div>
              <div className="footer-section">
                <h3>{t('quickLinks')}</h3>
                <ul className="footer-links">
                  <li><Link to="/">{t('home')}</Link></li>
                  <li><Link to="/family-tree">{t('familyTree')}</Link></li>
                  <li><Link to="/events">{t('events')}</Link></li>
                  <li><Link to="/contributions">{t('contributions')}</Link></li>
                </ul>
              </div>
              <div className="footer-section">
                <h3>{t('contact')}</h3>
                <ul className="footer-links">
                  <li><a href="#"><i className="fab fa-whatsapp"></i> {t('whatsappGroup')}</a></li>
                  <li><a href="mailto:contact@sanhoua.com">contact@sanhoua.com</a></li>
                </ul>
              </div>
            </div>
            <div className="footer-bottom">
              <p>&copy; {new Date().getFullYear()} {t('familySanhoua')}. {t('allRightsReserved')}</p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
