import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Login = ({ onLogin }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleWhatsAppLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // In a real implementation, this would connect to WhatsApp API
      // For now, we'll simulate the authentication process
      const response = await axios.post('/api/auth/whatsapp', { phoneNumber });
      
      if (response.data.success) {
        onLogin(response.data.user, response.data.token);
      } else {
        setError(response.data.message || 'Authentication failed');
      }
    } catch (err) {
      setError('Error connecting to WhatsApp. Please try again.');
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="login-container" style={{ maxWidth: '500px', margin: '3rem auto', padding: '2rem', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <h1 className="section-title">{t('login')}</h1>
        
        {error && (
          <div className="error-message" style={{ backgroundColor: '#FFEBEE', color: '#D32F2F', padding: '1rem', borderRadius: '4px', marginBottom: '1rem' }}>
            {error}
          </div>
        )}
        
        <div className="whatsapp-login-section">
          <p>{t('loginWithWhatsAppDescription')}</p>
          
          <form onSubmit={handleWhatsAppLogin}>
            <div className="form-group">
              <label htmlFor="phoneNumber">{t('whatsappPhoneNumber')}</label>
              <input 
                type="tel" 
                id="phoneNumber" 
                className="form-control" 
                placeholder="+1234567890"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <small>{t('includeCountryCode')}</small>
            </div>
            
            <button 
              type="submit" 
              className="whatsapp-login" 
              style={{ width: '100%', justifyContent: 'center', marginTop: '1rem' }}
              disabled={isLoading}
            >
              {isLoading ? t('connecting') : t('loginWithWhatsApp')}
            </button>
          </form>
          
          <div className="login-divider" style={{ margin: '2rem 0', textAlign: 'center', position: 'relative' }}>
            <span style={{ backgroundColor: 'white', padding: '0 1rem', position: 'relative', zIndex: '1' }}>{t('or')}</span>
            <hr style={{ position: 'absolute', top: '50%', left: '0', right: '0', margin: '0' }} />
          </div>
          
          <div className="demo-login" style={{ textAlign: 'center' }}>
            <p>{t('demoLoginMessage')}</p>
            <button 
              className="btn btn-secondary"
              onClick={() => {
                // Simulate login with demo account
                const demoUser = {
                  id: 1,
                  username: 'Demo User',
                  whatsappId: '+1234567890',
                  role: 'user',
                  preferredLanguage: 'fr'
                };
                onLogin(demoUser, 'demo-token-123');
              }}
            >
              {t('demoLogin')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
