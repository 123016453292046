import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatter from '../components/DateFormatter';
import WhatsAppShare from '../components/WhatsAppShare';

const Events = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    eventType: '',
    dateRange: ''
  });

  useEffect(() => {
    // In a real implementation, this would fetch data from the backend
    // For now, we'll use mock data
    const fetchEvents = async () => {
      try {
        setIsLoading(true);
        
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Mock data for demonstration
        const mockEvents = [
          {
            id: 1,
            title: 'Réunion Mensuelle de Mars',
            description: 'Compte rendu de la réunion mensuelle de mars. Nous avons discuté des projets familiaux et planifié les activités pour le trimestre à venir.',
            eventDate: '2025-03-15T19:00:00',
            location: 'Vidéoconférence',
            eventType: 'meeting',
            photos: [
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100'
            ],
            comments: [
              {
                id: 1,
                author: 'Marie Sanhoua',
                date: '2025-03-16T10:23:00',
                content: 'Merci pour l\'organisation de cette réunion. Les décisions prises sont très importantes pour notre avenir.'
              },
              {
                id: 2,
                author: 'Paul Sanhoua',
                date: '2025-03-16T14:05:00',
                content: 'Je suis d\'accord avec les projets proposés. Hâte de commencer!'
              },
              {
                id: 3,
                author: 'Sophie Sanhoua',
                date: '2025-03-17T09:17:00',
                content: 'Désolée d\'avoir manqué la réunion. Merci pour le résumé détaillé.'
              }
            ],
            hasTranscript: true
          },
          {
            id: 2,
            title: 'Célébration d\'Anniversaire',
            description: 'Célébration des anniversaires du mois de février. Une belle journée en famille avec beaucoup de joie et de souvenirs créés.',
            eventDate: '2025-02-28T16:00:00',
            location: 'Résidence Sanhoua',
            eventType: 'celebration',
            photos: [
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100',
              'https://via.placeholder.com/150x100'
            ],
            comments: [
              {
                id: 4,
                author: 'Claire Sanhoua',
                date: '2025-03-01T11:45:00',
                content: 'C\'était une journée merveilleuse! Merci à tous pour les cadeaux et les vœux.'
              },
              {
                id: 5,
                author: 'Marc Sanhoua',
                date: '2025-03-02T08:30:00',
                content: 'Les photos sont superbes! Pouvez-vous partager l\'album complet?'
              }
            ],
            hasTranscript: false
          }
        ];
        
        setEvents(mockEvents);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load events');
        setIsLoading(false);
        console.error('Error fetching events:', err);
      }
    };

    fetchEvents();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredEvents = events.filter(event => {
    // Apply search filter
    if (filters.search && !event.title.toLowerCase().includes(filters.search.toLowerCase()) && 
        !event.description.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    
    // Apply event type filter
    if (filters.eventType && event.eventType !== filters.eventType) {
      return false;
    }
    
    // Apply date range filter (simplified for demo)
    if (filters.dateRange) {
      const eventDate = new Date(event.eventDate);
      const now = new Date();
      
      if (filters.dateRange === 'this-month') {
        if (eventDate.getMonth() !== now.getMonth() || eventDate.getFullYear() !== now.getFullYear()) {
          return false;
        }
      } else if (filters.dateRange === 'last-month') {
        const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
        const lastMonthYear = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
        if (eventDate.getMonth() !== lastMonth || eventDate.getFullYear() !== lastMonthYear) {
          return false;
        }
      } else if (filters.dateRange === 'this-year') {
        if (eventDate.getFullYear() !== now.getFullYear()) {
          return false;
        }
      } else if (filters.dateRange === 'last-year') {
        if (eventDate.getFullYear() !== now.getFullYear() - 1) {
          return false;
        }
      }
    }
    
    return true;
  });

  const handleAddComment = (eventId, comment) => {
    // In a real implementation, this would send the comment to the backend
    console.log(`Adding comment to event ${eventId}:`, comment);
    
    // For demo, we'll just update the local state
    const updatedEvents = events.map(event => {
      if (event.id === eventId) {
        return {
          ...event,
          comments: [
            ...event.comments,
            {
              id: Date.now(), // Use timestamp as temporary ID
              author: 'Current User', // Would be the logged-in user
              date: new Date().toISOString(),
              content: comment
            }
          ]
        };
      }
      return event;
    });
    
    setEvents(updatedEvents);
  };

  return (
    <div className="container">
      <h1 className="section-title">{t('events')}</h1>
      
      <div className="event-filters">
        <div className="event-search">
          <input 
            type="text" 
            className="form-control" 
            placeholder={t('searchEvent')}
            name="search"
            value={filters.search}
            onChange={handleFilterChange}
          />
        </div>
        <select 
          name="eventType" 
          value={filters.eventType}
          onChange={handleFilterChange}
        >
          <option value="">{t('allTypes')}</option>
          <option value="meeting">{t('meetings')}</option>
          <option value="celebration">{t('celebrations')}</option>
          <option value="announcement">{t('announcements')}</option>
          <option value="other">{t('others')}</option>
        </select>
        <select 
          name="dateRange" 
          value={filters.dateRange}
          onChange={handleFilterChange}
        >
          <option value="">{t('allDates')}</option>
          <option value="this-month">{t('thisMonth')}</option>
          <option value="last-month">{t('lastMonth')}</option>
          <option value="this-year">{t('thisYear')}</option>
          <option value="last-year">{t('lastYear')}</option>
        </select>
      </div>
      
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>{t('loading')}</p>
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', padding: '2rem', color: 'red' }}>
          <p>{error}</p>
        </div>
      ) : filteredEvents.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>{t('noEventsFound')}</p>
        </div>
      ) : (
        <>
          {filteredEvents.map(event => (
            <div key={event.id} className="event-card card">
              <div className="card-header">
                <h3>{event.title}</h3>
                <p>
                  <i className="far fa-calendar"></i> <DateFormatter date={event.eventDate} format="long" /> | 
                  <i className="far fa-clock"></i> <DateFormatter date={event.eventDate} format="time" /> | 
                  <i className="fas fa-map-marker-alt"></i> {event.location}
                </p>
              </div>
              <div className="card-body">
                <p>{event.description}</p>
                
                {event.photos && event.photos.length > 0 && (
                  <div className="event-photos">
                    {event.photos.map((photo, index) => (
                      <img key={index} src={photo} alt={`Event photo ${index + 1}`} />
                    ))}
                  </div>
                )}
                
                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                  {event.hasTranscript && (
                    <a href={`/transcripts/${event.id}`} className="btn btn-secondary">
                      {t('viewTranscript')}
                    </a>
                  )}
                  
                  {event.photos && event.photos.length > 0 && (
                    <a href={`/events/${event.id}/photos`} className="btn btn-secondary">
                      {t('viewAllPhotos')}
                    </a>
                  )}
                  
                  <WhatsAppShare 
                    url={`https://sanhoua.com/events/${event.id}`} 
                    title={event.title} 
                    message={event.description}
                  />
                </div>
                
                <div className="event-comments">
                  <h4>{t('comments')} ({event.comments.length})</h4>
                  
                  {event.comments.map(comment => (
                    <div key={comment.id} className="comment">
                      <div className="comment-header">
                        <span className="comment-author">{comment.author}</span>
                        <span className="comment-date">
                          <DateFormatter date={comment.date} format="datetime" />
                        </span>
                      </div>
                      <p>{comment.content}</p>
                    </div>
                  ))}
                  
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    const comment = e.target.comment.value;
                    if (comment.trim()) {
                      handleAddComment(event.id, comment);
                      e.target.comment.value = '';
                    }
                  }}>
                    <div className="form-group">
                      <textarea 
                        name="comment"
                        className="form-control" 
                        rows="3" 
                        placeholder={t('addComment')}
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">{t('comment')}</button>
                  </form>
                </div>
              </div>
            </div>
          ))}
          
          {/* Pagination */}
          <div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>1</a>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>2</a>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>3</a>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>»</a>
          </div>
        </>
      )}
      
      {/* Add Event Button */}
      <a href="/events/new" className="add-event-btn">
        <i className="fas fa-plus"></i>
      </a>
    </div>
  );
};

export default Events;
