import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';

const FamilyTreeVisualization = () => {
  const { t } = useTranslation();
  const [familyData, setFamilyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const svgRef = useRef(null);

  useEffect(() => {
    // In a real implementation, this would fetch data from the backend
    // For now, we'll use mock data
    const fetchFamilyData = async () => {
      try {
        setIsLoading(true);
        
        // Mock data for demonstration
        const mockData = {
          name: "Sanhoua Aîné",
          id: 1,
          birthYear: 1920,
          deathYear: 1990,
          gender: "male",
          bio: "Fondateur",
          profilePicture: "https://via.placeholder.com/80",
          children: [
            {
              name: "Jean Sanhoua",
              id: 2,
              birthYear: 1945,
              deathYear: 2015,
              gender: "male",
              bio: "Fils aîné",
              profilePicture: "https://via.placeholder.com/80",
              children: [
                {
                  name: "Paul Sanhoua",
                  id: 5,
                  birthYear: 1970,
                  gender: "male",
                  bio: "Petit-fils",
                  profilePicture: "https://via.placeholder.com/80"
                },
                {
                  name: "Sophie Sanhoua",
                  id: 6,
                  birthYear: 1972,
                  gender: "female",
                  bio: "Petite-fille",
                  profilePicture: "https://via.placeholder.com/80"
                }
              ]
            },
            {
              name: "Marie Sanhoua",
              id: 3,
              birthYear: 1948,
              gender: "female",
              bio: "Fille",
              profilePicture: "https://via.placeholder.com/80",
              children: [
                {
                  name: "Marc Sanhoua",
                  id: 7,
                  birthYear: 1975,
                  gender: "male",
                  bio: "Petit-fils",
                  profilePicture: "https://via.placeholder.com/80"
                }
              ]
            },
            {
              name: "Pierre Sanhoua",
              id: 4,
              birthYear: 1950,
              gender: "male",
              bio: "Fils cadet",
              profilePicture: "https://via.placeholder.com/80",
              children: [
                {
                  name: "Claire Sanhoua",
                  id: 8,
                  birthYear: 1980,
                  gender: "female",
                  bio: "Petite-fille",
                  profilePicture: "https://via.placeholder.com/80"
                },
                {
                  name: "Thomas Sanhoua",
                  id: 9,
                  birthYear: 1982,
                  gender: "male",
                  bio: "Petit-fils",
                  profilePicture: "https://via.placeholder.com/80"
                }
              ]
            }
          ]
        };
        
        setFamilyData(mockData);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load family tree data');
        setIsLoading(false);
        console.error('Error fetching family data:', err);
      }
    };

    fetchFamilyData();
  }, []);

  useEffect(() => {
    if (!familyData || !svgRef.current) return;

    // Clear previous visualization
    d3.select(svgRef.current).selectAll("*").remove();

    // Set up the d3 tree layout
    const width = 1000;
    const height = 800;
    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create the SVG container
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

    // Create a group element for the tree
    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create the tree layout
    const root = d3.hierarchy(familyData);
    const treeLayout = d3.tree().size([innerWidth, innerHeight]);
    const treeData = treeLayout(root);

    // Add links between nodes
    g.selectAll(".link")
      .data(treeData.links())
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", d3.linkVertical()
        .x(d => d.x)
        .y(d => d.y))
      .attr("fill", "none")
      .attr("stroke", "#009E60")
      .attr("stroke-width", 2);

    // Create node groups
    const node = g.selectAll(".node")
      .data(treeData.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", d => `translate(${d.x},${d.y})`)
      .attr("cursor", "pointer")
      .on("click", (event, d) => {
        // Handle node click - could show more details or expand/collapse
        console.log("Node clicked:", d.data);
      });

    // Add node backgrounds
    node.append("rect")
      .attr("width", 120)
      .attr("height", 100)
      .attr("x", -60)
      .attr("y", -50)
      .attr("rx", 8)
      .attr("ry", 8)
      .attr("fill", "white")
      .attr("stroke", d => d.data.gender === "female" ? "#FF7900" : "#009E60")
      .attr("stroke-width", 2);

    // Add profile pictures
    node.append("clipPath")
      .attr("id", d => `clip-${d.data.id}`)
      .append("circle")
      .attr("r", 25);

    node.append("image")
      .attr("xlink:href", d => d.data.profilePicture)
      .attr("clip-path", d => `url(#clip-${d.data.id})`)
      .attr("x", -25)
      .attr("y", -40)
      .attr("width", 50)
      .attr("height", 50);

    // Add names
    node.append("text")
      .attr("dy", 25)
      .attr("text-anchor", "middle")
      .attr("font-weight", "bold")
      .attr("font-size", "12px")
      .text(d => d.data.name);

    // Add years
    node.append("text")
      .attr("dy", 40)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .text(d => {
        if (d.data.deathYear) {
          return `${d.data.birthYear} - ${d.data.deathYear}`;
        }
        return `${d.data.birthYear}`;
      });

    // Add zoom functionality
    const zoom = d3.zoom()
      .scaleExtent([0.5, 3])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
        setZoomLevel(event.transform.k);
      });

    svg.call(zoom);

  }, [familyData]);

  const handleZoomIn = () => {
    const svg = d3.select(svgRef.current);
    const zoom = d3.zoom().scaleExtent([0.5, 3]);
    svg.transition().call(zoom.scaleBy, 1.2);
  };

  const handleZoomOut = () => {
    const svg = d3.select(svgRef.current);
    const zoom = d3.zoom().scaleExtent([0.5, 3]);
    svg.transition().call(zoom.scaleBy, 0.8);
  };

  const handleResetZoom = () => {
    const svg = d3.select(svgRef.current);
    const zoom = d3.zoom().scaleExtent([0.5, 3]);
    svg.transition().call(zoom.transform, d3.zoomIdentity);
  };

  return (
    <div className="family-tree-visualization">
      <div className="tree-controls" style={{ marginBottom: '1rem' }}>
        <button onClick={handleZoomIn}><i className="fas fa-plus"></i> {t('zoomIn')}</button>
        <button onClick={handleZoomOut}><i className="fas fa-minus"></i> {t('zoomOut')}</button>
        <button onClick={handleResetZoom}><i className="fas fa-expand"></i> {t('fullView')}</button>
        <span style={{ marginLeft: '1rem' }}>Zoom: {Math.round(zoomLevel * 100)}%</span>
      </div>

      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>Loading family tree visualization...</p>
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', padding: '2rem', color: 'red' }}>
          <p>{error}</p>
        </div>
      ) : (
        <div className="tree-container" style={{ overflowX: 'auto', border: '1px solid #E0E0E0', borderRadius: '8px', backgroundColor: '#F5F5F5' }}>
          <svg ref={svgRef}></svg>
        </div>
      )}
    </div>
  );
};

export default FamilyTreeVisualization;
