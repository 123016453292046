import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatter from '../components/DateFormatter';
import CurrencyFormatter from '../components/CurrencyFormatter';

const Contributions = () => {
  const { t } = useTranslation();
  const [contributions, setContributions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    status: '',
    period: ''
  });
  const [stats, setStats] = useState({
    totalAmount: 0,
    activeMembers: 0,
    totalMembers: 0,
    participationRate: 0
  });

  useEffect(() => {
    // In a real implementation, this would fetch data from the backend
    // For now, we'll use mock data
    const fetchContributions = async () => {
      try {
        setIsLoading(true);
        
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Mock data for demonstration
        const mockContributions = [
          {
            id: 1,
            member: 'Jean Sanhoua',
            date: '2025-03-05T10:15:00',
            amount: 5,
            currency: 'EUR',
            status: 'completed',
            notes: 'Paiement pour Mars 2025'
          },
          {
            id: 2,
            member: 'Marie Sanhoua',
            date: '2025-03-03T14:30:00',
            amount: 5,
            currency: 'USD',
            status: 'completed',
            notes: 'Paiement pour Mars 2025'
          },
          {
            id: 3,
            member: 'Paul Sanhoua',
            date: '2025-03-10T09:45:00',
            amount: 2500,
            currency: 'FCFA',
            status: 'completed',
            notes: 'Paiement pour Mars 2025'
          },
          {
            id: 4,
            member: 'Sophie Sanhoua',
            date: null,
            amount: 5,
            currency: 'EUR',
            status: 'pending',
            notes: 'En attente pour Mars 2025'
          },
          {
            id: 5,
            member: 'Marc Sanhoua',
            date: null,
            amount: 5,
            currency: 'USD',
            status: 'overdue',
            notes: 'En retard pour Février 2025'
          }
        ];
        
        setContributions(mockContributions);
        
        // Calculate statistics
        const totalAmount = mockContributions
          .filter(c => c.status === 'completed')
          .reduce((sum, c) => {
            // Convert all to USD for simplicity in this demo
            let amount = c.amount;
            if (c.currency === 'EUR') amount *= 1.1; // Example conversion rate
            if (c.currency === 'FCFA') amount = amount / 500; // Example conversion rate
            return sum + amount;
          }, 0);
        
        const activeMembers = mockContributions.filter(c => c.status === 'completed').length;
        const totalMembers = mockContributions.length;
        const participationRate = (activeMembers / totalMembers) * 100;
        
        setStats({
          totalAmount,
          activeMembers,
          totalMembers,
          participationRate
        });
        
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load contributions');
        setIsLoading(false);
        console.error('Error fetching contributions:', err);
      }
    };

    fetchContributions();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredContributions = contributions.filter(contribution => {
    // Apply search filter
    if (filters.search && !contribution.member.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    
    // Apply status filter
    if (filters.status && contribution.status !== filters.status) {
      return false;
    }
    
    // Apply period filter (simplified for demo)
    if (filters.period) {
      // In a real implementation, this would filter by month/year
      return true;
    }
    
    return true;
  });

  return (
    <div className="container">
      <h1 className="section-title">{t('contributions')}</h1>
      
      {/* Contribution Statistics */}
      <div className="contribution-stats">
        <div className="stats-grid">
          <div className="stat-card">
            <h3>{t('totalContributions')}</h3>
            <div className="stat-value">$<span>{stats.totalAmount.toFixed(2)}</span></div>
            <div className="stat-info">{t('since')} 2025</div>
          </div>
          <div className="stat-card">
            <h3>{t('activeMembers')}</h3>
            <div className="stat-value">{stats.activeMembers} <span>{t('outOf')} {stats.totalMembers} {t('totalMembers')}</span></div>
            <div className="stat-info">{t('thisMonth')}</div>
          </div>
          <div className="stat-card">
            <h3>{t('participationRate')}</h3>
            <div className="stat-value">{stats.participationRate.toFixed(1)}<span>%</span></div>
            <div className="stat-info">{t('thisMonth')}</div>
          </div>
        </div>
      </div>
      
      {/* Contribution Reminder */}
      <div className="contribution-reminder card">
        <div className="card-header">
          <h3>{t('reminderAmounts')}</h3>
        </div>
        <div className="card-body">
          <p>{t('contributionReminder')}</p>
          <ul>
            <li>$5 USD</li>
            <li>5€ EUR</li>
            <li>2,500 FCFA</li>
          </ul>
          <p>{t('paymentDeadline')}</p>
        </div>
      </div>
      
      {/* Contribution Filters */}
      <div className="contribution-filters">
        <div className="contribution-search">
          <input 
            type="text" 
            className="form-control" 
            placeholder={t('searchMember')}
            name="search"
            value={filters.search}
            onChange={handleFilterChange}
          />
        </div>
        <select 
          name="status" 
          value={filters.status}
          onChange={handleFilterChange}
        >
          <option value="">{t('allStatuses')}</option>
          <option value="completed">{t('completed')}</option>
          <option value="pending">{t('pending')}</option>
          <option value="overdue">{t('overdue')}</option>
        </select>
        <select 
          name="period" 
          value={filters.period}
          onChange={handleFilterChange}
        >
          <option value="">{t('allPeriods')}</option>
          <option value="current-month">{t('thisMonth')}</option>
          <option value="last-month">{t('lastMonth')}</option>
          <option value="current-year">{t('thisYear')}</option>
        </select>
      </div>
      
      {/* Contribution Table */}
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>{t('loading')}</p>
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', padding: '2rem', color: 'red' }}>
          <p>{error}</p>
        </div>
      ) : filteredContributions.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>{t('noContributionsFound')}</p>
        </div>
      ) : (
        <div className="contribution-table-container">
          <table className="contribution-table">
            <thead>
              <tr>
                <th>{t('member')}</th>
                <th>{t('date')}</th>
                <th>{t('amount')}</th>
                <th>{t('status')}</th>
                <th>{t('notes')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredContributions.map(contribution => (
                <tr key={contribution.id} className={`status-${contribution.status}`}>
                  <td>{contribution.member}</td>
                  <td>
                    {contribution.date ? (
                      <DateFormatter date={contribution.date} format="short" />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <CurrencyFormatter amount={contribution.amount} currency={contribution.currency} />
                  </td>
                  <td>
                    <span className={`status-badge ${contribution.status}`}>
                      {t(contribution.status)}
                    </span>
                  </td>
                  <td>{contribution.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      {/* Admin Actions */}
      <div className="admin-actions" style={{ marginTop: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
        <button className="btn btn-primary">
          <i className="fas fa-plus"></i> {t('recordContribution')}
        </button>
        <button className="btn btn-secondary" style={{ marginLeft: '1rem' }}>
          <i className="fas fa-file-export"></i> {t('exportReport')}
        </button>
      </div>
    </div>
  );
};

export default Contributions;
