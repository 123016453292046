import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <h1>{t('welcome')}</h1>
          <p>{t('welcomeSubtitle')}</p>
          <Link to="/family-tree" className="btn btn-primary">{t('explore')}</Link>
          <Link to="/login" className="whatsapp-login">
            <i className="fab fa-whatsapp"></i> {t('loginWithWhatsApp')}
          </Link>
        </div>
      </section>

      {/* Main Content */}
      <div className="main">
        <div className="container">
          {/* Recent Events Section */}
          <section className="section">
            <h2 className="section-title">{t('recentEvents')}</h2>
            <div className="grid">
              <div className="card">
                <div className="card-header">
                  <h3>Réunion Mensuelle</h3>
                  <p>15 Mars 2025</p>
                </div>
                <div className="card-body">
                  <p>Discussion sur les projets familiaux et planification des activités pour le trimestre.</p>
                </div>
                <div className="card-footer">
                  <Link to="/events">{t('viewDetails')}</Link>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3>Célébration d'Anniversaire</h3>
                  <p>28 Février 2025</p>
                </div>
                <div className="card-body">
                  <p>Célébration des anniversaires du mois de février. Photos et vidéos disponibles.</p>
                </div>
                <div className="card-footer">
                  <Link to="/events">{t('viewPhotos')}</Link>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3>Annonce Importante</h3>
                  <p>10 Février 2025</p>
                </div>
                <div className="card-body">
                  <p>Mise à jour des informations de contact et des procédures de contribution mensuelle.</p>
                </div>
                <div className="card-footer">
                  <Link to="/events">{t('readMore')}</Link>
                </div>
              </div>
            </div>
          </section>

          {/* Family Highlights Section */}
          <section className="section">
            <h2 className="section-title">{t('familyMembers')}</h2>
            <div className="grid">
              <div className="card">
                <div className="card-body">
                  <h3>{t('ourHistory')}</h3>
                  <p>{t('discoverHistory')}</p>
                  <Link to="#" className="btn btn-secondary">{t('learnMore')}</Link>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h3>{t('familyTree')}</h3>
                  <p>{t('exploreTree')}</p>
                  <Link to="/family-tree" className="btn btn-secondary">{t('viewTree')}</Link>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h3>{t('newMembers')}</h3>
                  <p>{t('welcomeNewMembers')}</p>
                  <Link to="#" className="btn btn-secondary">{t('viewProfiles')}</Link>
                </div>
              </div>
            </div>
          </section>

          {/* Contribution Status */}
          <section className="section">
            <h2 className="section-title">{t('contributionStatus')}</h2>
            <div className="card">
              <div className="card-body">
                <p>{t('monthlyContribution')}</p>
                <div className="contribution-summary" style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
                  <div>
                    <h4>{t('yourStatus')}:</h4>
                    <span className="contribution-status status-completed">{t('upToDate')}</span>
                  </div>
                  <div>
                    <h4>{t('nextPayment')}:</h4>
                    <p>1 Mai 2025</p>
                  </div>
                </div>
                <Link to="/contributions" className="btn btn-secondary">{t('viewHistory')}</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Home;
