import React from 'react';
import { useTranslation } from 'react-i18next';

const CurrencyFormatter = ({ amount, currency }) => {
  const { i18n } = useTranslation();
  
  if (amount === undefined || amount === null) return null;
  
  // Format options based on the currency
  let formatter;
  switch (currency) {
    case 'USD':
      formatter = new Intl.NumberFormat(i18n.language === 'fr' ? 'fr-FR' : 'en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });
      break;
    case 'EUR':
      formatter = new Intl.NumberFormat(i18n.language === 'fr' ? 'fr-FR' : 'en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });
      break;
    case 'FCFA':
      // FCFA doesn't have a standard currency code in Intl.NumberFormat
      // So we format it manually
      formatter = new Intl.NumberFormat(i18n.language === 'fr' ? 'fr-FR' : 'en-US', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return (
        <span className="formatted-currency">
          {formatter.format(amount)} FCFA
        </span>
      );
    default:
      formatter = new Intl.NumberFormat(i18n.language === 'fr' ? 'fr-FR' : 'en-US', {
        style: 'decimal',
        minimumFractionDigits: 2
      });
      return (
        <span className="formatted-currency">
          {formatter.format(amount)}
        </span>
      );
  }
  
  return (
    <span className="formatted-currency">
      {formatter.format(amount)}
    </span>
  );
};

export default CurrencyFormatter;
