import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WhatsAppService from '../services/WhatsAppService';

const WhatsAppShare = ({ url, title, message }) => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleShare = () => {
    setIsModalOpen(true);
  };
  
  const handleSend = () => {
    if (!phoneNumber) {
      return;
    }
    
    // Format the share message
    const shareMessage = `${title || t('checkThisOut')}: ${message || ''} ${url}`;
    
    // Generate WhatsApp deep link
    const deepLink = WhatsAppService.getWhatsAppDeepLink(phoneNumber, shareMessage);
    
    // Open WhatsApp in a new tab
    window.open(deepLink, '_blank');
    
    // Close the modal
    setIsModalOpen(false);
    setPhoneNumber('');
  };
  
  return (
    <div className="whatsapp-share">
      <button 
        onClick={handleShare}
        style={{
          backgroundColor: '#25D366',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          padding: '0.5rem 1rem',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <i className="fab fa-whatsapp" style={{ marginRight: '0.5rem' }}></i>
        {t('shareViaWhatsApp')}
      </button>
      
      {isModalOpen && (
        <div className="modal-overlay" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div className="modal-content" style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '90%',
            maxWidth: '400px',
            padding: '1.5rem'
          }}>
            <div className="modal-header" style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem'
            }}>
              <h3 style={{ margin: 0 }}>{t('shareViaWhatsApp')}</h3>
              <button 
                onClick={() => setIsModalOpen(false)} 
                style={{
                  background: 'none',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer'
                }}
              >
                &times;
              </button>
            </div>
            
            <div className="form-group">
              <label htmlFor="phoneNumber">{t('enterPhoneNumber')}</label>
              <input 
                type="tel" 
                id="phoneNumber" 
                className="form-control" 
                placeholder="+1234567890"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  marginTop: '0.5rem',
                  marginBottom: '1rem',
                  borderRadius: '4px',
                  border: '1px solid #ccc'
                }}
              />
              <small style={{ display: 'block', marginBottom: '1rem', color: '#666' }}>
                {t('includeCountryCode')}
              </small>
            </div>
            
            <div className="modal-actions" style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <button 
                onClick={() => setIsModalOpen(false)}
                style={{
                  backgroundColor: '#f0f0f0',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '0.5rem 1rem',
                  marginRight: '0.5rem',
                  cursor: 'pointer'
                }}
              >
                {t('cancel')}
              </button>
              <button 
                onClick={handleSend}
                style={{
                  backgroundColor: '#25D366',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '0.5rem 1rem',
                  cursor: 'pointer'
                }}
              >
                {t('send')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppShare;
