import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FamilyTreeVisualization from '../components/FamilyTreeVisualization';

const FamilyTree = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('visual'); // 'visual' or 'list'

  return (
    <div className="container">
      <h1 className="section-title">{t('familyTree')}</h1>
      
      <div className="view-mode-toggle" style={{ marginBottom: '1rem' }}>
        <button 
          className={`btn ${viewMode === 'visual' ? 'btn-primary' : 'btn-secondary'}`}
          onClick={() => setViewMode('visual')}
          style={{ marginRight: '0.5rem' }}
        >
          <i className="fas fa-sitemap"></i> {t('visualView')}
        </button>
        <button 
          className={`btn ${viewMode === 'list' ? 'btn-primary' : 'btn-secondary'}`}
          onClick={() => setViewMode('list')}
        >
          <i className="fas fa-list"></i> {t('listView')}
        </button>
      </div>
      
      <div className="tree-search">
        <input 
          type="text" 
          placeholder={t('searchFamilyMember')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
        />
      </div>
      
      {viewMode === 'visual' ? (
        <div className="family-tree-container" style={{ marginTop: '1.5rem' }}>
          <FamilyTreeVisualization searchTerm={searchTerm} />
        </div>
      ) : (
        <div className="family-list-container" style={{ marginTop: '1.5rem' }}>
          <div className="card">
            <div className="card-header">
              <h3>{t('familyMembersList')}</h3>
            </div>
            <div className="card-body">
              <p>{t('listViewDescription')}</p>
              <table className="table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th>{t('name')}</th>
                    <th>{t('birthYear')}</th>
                    <th>{t('relationship')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Sanhoua Aîné</td>
                    <td>1920</td>
                    <td>{t('founder')}</td>
                    <td>
                      <button className="btn btn-sm btn-secondary">{t('viewDetails')}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Jean Sanhoua</td>
                    <td>1945</td>
                    <td>{t('son')}</td>
                    <td>
                      <button className="btn btn-sm btn-secondary">{t('viewDetails')}</button>
                    </td>
                  </tr>
                  <tr>
                    <td>Marie Sanhoua</td>
                    <td>1948</td>
                    <td>{t('daughter')}</td>
                    <td>
                      <button className="btn btn-sm btn-secondary">{t('viewDetails')}</button>
                    </td>
                  </tr>
                  {/* More family members would be listed here */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      
      <div className="card" style={{ marginTop: '2rem' }}>
        <div className="card-header">
          <h3>{t('addFamilyMember')}</h3>
        </div>
        <div className="card-body">
          <p>{t('adminFamilyTreeDescription')}</p>
          <button className="btn btn-primary">{t('addMember')}</button>
        </div>
      </div>
    </div>
  );
};

export default FamilyTree;
