import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateFormatter from '../components/DateFormatter';
import WhatsAppShare from '../components/WhatsAppShare';

const Transcripts = () => {
  const { t } = useTranslation();
  const [transcripts, setTranscripts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    author: '',
    dateRange: ''
  });

  useEffect(() => {
    // In a real implementation, this would fetch data from the backend
    // For now, we'll use mock data
    const fetchTranscripts = async () => {
      try {
        setIsLoading(true);
        
        // Simulate API call
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Mock data for demonstration
        const mockTranscripts = [
          {
            id: 1,
            title: 'Compte Rendu de la Réunion Mensuelle - Mars 2025',
            meetingDate: '2025-03-15T19:00:00',
            recordedBy: 'Jean Sanhoua',
            duration: '1h 30min',
            participants: 15,
            content: `
              <h2>Ordre du jour</h2>
              <ol>
                <li>Bilan des activités du mois précédent</li>
                <li>Point sur les contributions</li>
                <li>Planification des événements à venir</li>
                <li>Questions diverses</li>
              </ol>
              
              <h2>1. Bilan des activités</h2>
              <p>La célébration des anniversaires du mois de février a été un grand succès. Tous les membres présents ont apprécié l'organisation et l'ambiance. Les photos ont été partagées sur le groupe WhatsApp et sont maintenant disponibles sur le site.</p>
              
              <h2>2. Point sur les contributions</h2>
              <p>80% des membres sont à jour dans leurs contributions pour le mois de mars. Un rappel sera envoyé aux membres qui n'ont pas encore effectué leur paiement.</p>
              
              <h2>3. Planification des événements</h2>
              <p>Deux événements majeurs sont prévus pour le trimestre :</p>
              <ul>
                <li>Réunion familiale annuelle : prévue pour le 15 juin 2025</li>
                <li>Journée culturelle : prévue pour le 30 juillet 2025</li>
              </ul>
              
              <h2>4. Questions diverses</h2>
              <p>Plusieurs membres ont suggéré d'organiser des sessions de partage de connaissances sur l'histoire familiale pour les plus jeunes. Cette proposition sera étudiée lors de la prochaine réunion.</p>
              
              <h2>Conclusion</h2>
              <p>La prochaine réunion mensuelle est fixée au 12 avril 2025 à 19h00.</p>
            `,
            attachments: [
              {
                name: 'budget_previsionnel_2025.pdf',
                url: '#'
              },
              {
                name: 'liste_participants_mars.xlsx',
                url: '#'
              }
            ]
          },
          {
            id: 2,
            title: 'Compte Rendu de la Réunion Mensuelle - Février 2025',
            meetingDate: '2025-02-10T19:00:00',
            recordedBy: 'Marie Sanhoua',
            duration: '1h 15min',
            participants: 12,
            content: `
              <h2>Ordre du jour</h2>
              <ol>
                <li>Bilan des activités du mois précédent</li>
                <li>Point sur les contributions</li>
                <li>Organisation de la célébration des anniversaires</li>
                <li>Questions diverses</li>
              </ol>
              
              <h2>1. Bilan des activités</h2>
              <p>Le mois de janvier a été calme en termes d'activités familiales. La réunion de début d'année a permis de définir les objectifs pour 2025.</p>
              
              <h2>2. Point sur les contributions</h2>
              <p>75% des membres sont à jour dans leurs contributions pour le mois de février. Un rappel sera envoyé aux membres qui n'ont pas encore effectué leur paiement.</p>
              
              <h2>3. Organisation de la célébration des anniversaires</h2>
              <p>La célébration des anniversaires du mois de février aura lieu le 28 février à la résidence Sanhoua. Un comité d'organisation a été mis en place.</p>
              
              <h2>4. Questions diverses</h2>
              <p>La mise à jour du site web familial a été discutée. De nouvelles fonctionnalités seront ajoutées dans les prochains mois.</p>
              
              <h2>Conclusion</h2>
              <p>La prochaine réunion mensuelle est fixée au 15 mars 2025 à 19h00.</p>
            `,
            attachments: [
              {
                name: 'comite_organisation_anniversaires.pdf',
                url: '#'
              }
            ]
          }
        ];
        
        setTranscripts(mockTranscripts);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load transcripts');
        setIsLoading(false);
        console.error('Error fetching transcripts:', err);
      }
    };

    fetchTranscripts();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredTranscripts = transcripts.filter(transcript => {
    // Apply search filter
    if (filters.search && !transcript.title.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    
    // Apply author filter
    if (filters.author && transcript.recordedBy !== filters.author) {
      return false;
    }
    
    // Apply date range filter (simplified for demo)
    if (filters.dateRange) {
      const meetingDate = new Date(transcript.meetingDate);
      const now = new Date();
      
      if (filters.dateRange === 'this-month') {
        if (meetingDate.getMonth() !== now.getMonth() || meetingDate.getFullYear() !== now.getFullYear()) {
          return false;
        }
      } else if (filters.dateRange === 'last-month') {
        const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
        const lastMonthYear = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
        if (meetingDate.getMonth() !== lastMonth || meetingDate.getFullYear() !== lastMonthYear) {
          return false;
        }
      } else if (filters.dateRange === 'this-year') {
        if (meetingDate.getFullYear() !== now.getFullYear()) {
          return false;
        }
      }
    }
    
    return true;
  });

  return (
    <div className="container">
      <h1 className="section-title">{t('transcripts')}</h1>
      
      <div className="transcript-filters">
        <div className="transcript-search">
          <input 
            type="text" 
            className="form-control" 
            placeholder={t('searchTranscript')}
            name="search"
            value={filters.search}
            onChange={handleFilterChange}
          />
        </div>
        <select 
          name="author" 
          value={filters.author}
          onChange={handleFilterChange}
        >
          <option value="">{t('allAuthors')}</option>
          <option value="Jean Sanhoua">Jean Sanhoua</option>
          <option value="Marie Sanhoua">Marie Sanhoua</option>
          <option value="Paul Sanhoua">Paul Sanhoua</option>
        </select>
        <select 
          name="dateRange" 
          value={filters.dateRange}
          onChange={handleFilterChange}
        >
          <option value="">{t('allDates')}</option>
          <option value="this-month">{t('thisMonth')}</option>
          <option value="last-month">{t('lastMonth')}</option>
          <option value="this-year">{t('thisYear')}</option>
        </select>
      </div>
      
      {isLoading ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>{t('loading')}</p>
        </div>
      ) : error ? (
        <div style={{ textAlign: 'center', padding: '2rem', color: 'red' }}>
          <p>{error}</p>
        </div>
      ) : filteredTranscripts.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          <p>{t('noTranscriptsFound')}</p>
        </div>
      ) : (
        <>
          {filteredTranscripts.map(transcript => (
            <div key={transcript.id} className="transcript-card card">
              <div className="card-header">
                <h3>{transcript.title}</h3>
                <p>
                  <i className="far fa-calendar"></i> <DateFormatter date={transcript.meetingDate} format="long" /> | 
                  <i className="far fa-user"></i> {t('recordedBy')}: {transcript.recordedBy} | 
                  <i className="far fa-clock"></i> {transcript.duration} | 
                  <i className="fas fa-users"></i> {transcript.participants} {t('participants')}
                </p>
              </div>
              <div className="card-body">
                <div className="transcript-content" dangerouslySetInnerHTML={{ __html: transcript.content }}></div>
                
                {transcript.attachments && transcript.attachments.length > 0 && (
                  <div className="transcript-attachments">
                    <h4>{t('attachments')}</h4>
                    <ul>
                      {transcript.attachments.map((attachment, index) => (
                        <li key={index}>
                          <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-file"></i> {attachment.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                
                <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                  <button className="btn btn-secondary">
                    <i className="fas fa-file-pdf"></i> {t('downloadPDF')}
                  </button>
                  <button className="btn btn-secondary">
                    <i className="fas fa-print"></i> {t('print')}
                  </button>
                  <WhatsAppShare 
                    url={`https://sanhoua.com/transcripts/${transcript.id}`} 
                    title={transcript.title} 
                  />
                </div>
              </div>
            </div>
          ))}
          
          {/* Pagination */}
          <div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>1</a>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>2</a>
            <a href="#" style={{ margin: '0 0.5rem', padding: '0.5rem 1rem', border: '1px solid var(--medium-gray)', borderRadius: '4px', textDecoration: 'none' }}>»</a>
          </div>
        </>
      )}
      
      {/* Add Transcript Button (Admin only) */}
      <a href="/transcripts/new" className="add-transcript-btn">
        <i className="fas fa-plus"></i>
      </a>
    </div>
  );
};

export default Transcripts;
